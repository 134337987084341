import * as Resources from './Resources';
import * as User from './auth/user';

import * as Breadcrumbs from './infra/breadcrumbs';
import * as Configuration from './infra/configuration';
import * as Errors from './infra/errors';
import * as NotificationMenu from './infra/notifications';
import * as ReportMenu from './infra/reports';
import * as Screen from './infra/screen';

import * as AppointmentModal from './modals/appointmentModal';
import * as CompanyModal from './modals/companyModal';
import * as Container from './modals/container';
import * as Gallery from './modals/gallery';
import * as ItemDetails from './modals/itemDetails';
import * as ItemSearch from './modals/itemSearch';
import * as NewParty from './modals/newParty';
import * as PartySearch from './modals/partySearch';
import * as TaskModal from './modals/taskModal';
import * as TripSearch from './modals/tripSearch';

import * as ClientFile from './pages/clientFile/clientFile';
import * as GeneralInfo from './pages/clientFile/generalInfo';
import * as POItems from './pages/clientFile/POItems';
import * as PurchaseOrder from './pages/clientFile/purchaseOrder';
import * as CFShipping from './pages/clientFile/shipping';
import * as CFTransactions from './pages/clientFile/transactions';

import * as CampaignDetails from './pages/mainViews/campaignDetails';
import * as CRM from './pages/mainViews/crm';
import * as Files from './pages/mainViews/jobControl';
import * as Financials from './pages/mainViews/financials';
import * as Inventory from './pages/mainViews/inventory';
import * as Operations from './pages/mainViews/operations';
import * as QuickEntry from './pages/mainViews/quickEntry';
import * as Quotations from './pages/mainViews/quotations';
import * as ReusableCrates from './pages/mainViews/reusableCrates';
import * as Scheduling from './pages/mainViews/scheduling';
import * as Shipping from './pages/mainViews/shipping';
import * as Surveys from './pages/mainViews/surveys';
import * as Tasks from './pages/mainViews/tasks';
import * as Warehouses from './pages/mainViews/warehouses';

import * as ManagementBillableServiceConfig from './pages/mainViews/management/billableServiceConfig';
import * as ManagementCompanies from './pages/mainViews/management/companies';
import * as ManagementConfiguration from './pages/mainViews/management/configItems';
import * as ManagementExcelFields from './pages/mainViews/management/excelFields';
import * as ManagementExcelFieldSources from './pages/mainViews/management/excelFieldSources';
import * as ManagementExcelSources from './pages/mainViews/management/excelSources';
import * as ManagementExtendedDims from './pages/mainViews/management/extendedDims';
import * as ManagementItems from './pages/mainViews/management/itemConfig';
import * as ManagementPackingTypes from './pages/mainViews/management/packingTypes';
import * as ManagementQuote from './pages/mainViews/management/quote';
import * as ManagementRoles from './pages/mainViews/management/roles';
import * as ManagementResources from './pages/mainViews/management/resources';
import * as ManagementResourceDetails from './pages/mainViews/management/resourceDetails';
import * as ManagementSupplierRoutes from './pages/mainViews/management/supplierRoute';
import * as ManagementSupplierCosts from './pages/mainViews/management/supplierCost';
import * as ManagementSystemUserDetails from './pages/mainViews/management/systemUserDetails';
import * as ManagementSystemUsers from './pages/mainViews/management/systemUsers';
import * as ManagementUsers from './pages/mainViews/management/users';

import * as ShipmentDetails from './pages/shared/shipmentDetails';


// The top-level state object
export interface ApplicationState {
    resources: Resources.ResourcesState | undefined;
    user: User.UserState;

    breadcrumbs: Breadcrumbs.BreadcrumbsState;
    configuration: Configuration.ConfigurationState;
    errors: Errors.ErrorsState;
    notificationMenu: NotificationMenu.NotificationsState | undefined;
    reportMenu: ReportMenu.ReportsState | undefined;
    screen: Screen.ScreenState;

    appointmentModal: AppointmentModal.AppointmentModalState | undefined;
    companyModal: CompanyModal.CompanyModalState;
    containerModal: Container.ContainerModalState;
    gallery: Gallery.GalleryState;
    itemDetails: ItemDetails.ItemDetailsState;
    itemSearch: ItemSearch.ItemSearchState;
    newParty: NewParty.NewPartyState;
    partySearch: PartySearch.PartySearchState;
    taskModal: TaskModal.TaskModalState | undefined;
    tripSearch: TripSearch.TripSearchState;

    clientFile: ClientFile.ClientFileState;
    generalInfo: GeneralInfo.GeneralInfoState;
    poItems: POItems.POItemsState;
    purchaseOrder: PurchaseOrder.PurchaseOrderState;
    cfShipping: CFShipping.CFShippingState;
    cfTransactions: CFTransactions.CFTransactionsState;

    campaignDetails: CampaignDetails.CampaignDetailsState | undefined;
    crm: CRM.CRMState;
    files: Files.FilesState;
    financials: Financials.FinancialsState;
    inventory: Inventory.InventoryState;
    operations: Operations.OperationsState;
    quickEntry: QuickEntry.QuickEntryState;
    quotations: Quotations.QuotationsState;
    reusableCrates: ReusableCrates.ReusableCratesState;
    scheduling: Scheduling.SchedulingState | undefined;
    shipping: Shipping.ShippingState;
    surveys: Surveys.SurveysState;
    tasks: Tasks.TasksState;
    warehouses: Warehouses.WarehousesState;

    shipmentDetails: ShipmentDetails.ShipmentDetailsState;

    managementBillableServices: ManagementBillableServiceConfig.ManagementBillableServiceConfigState;
    managementCompanies: ManagementCompanies.ManagementCompaniesState;
    managementConfiguration: ManagementConfiguration.ManagementConfigurationState;
    managementExcelFields: ManagementExcelFields.ManagementExcelFieldsState;
    managementExcelFieldSources: ManagementExcelFieldSources.ManagementExcelFieldSourcesState;
    managementExcelSources: ManagementExcelSources.ManagementExcelSourcesState;
    managementExtendedDims: ManagementExtendedDims.ManagementExtendedDimsState;
    managementItems: ManagementItems.ManagementItemConfigState;
    managementPackingTypes: ManagementPackingTypes.ManagementPackingTypesState;
    managementRoles: ManagementRoles.ManagementRolesState;
    managementQuote: ManagementQuote.ManagementQuoteState | undefined;
    managementResources: ManagementResources.ManagementResourcesState;
    managementResourceDetails: ManagementResourceDetails.ResourceDetailsState;
    managementSupplierRoutes: ManagementSupplierRoutes.ManagementSupplierRouteState;
    managementSupplierCosts: ManagementSupplierCosts.ManagementSupplierCostState;
    managementSystemUserDetails: ManagementSystemUserDetails.ManagementSystemUserDetailsState;
    managementSystemUsers: ManagementSystemUsers.ManagementSystemUsersState;
    managementUsers: ManagementUsers.ManagementUsersState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    resources: Resources.reducer,
    user: User.reducer,

    breadcrumbs: Breadcrumbs.reducer,
    configuration: Configuration.reducer,
    errors: Errors.reducer,
    notificationMenu: NotificationMenu.reducer,
    reportMenu: ReportMenu.reducer,
    screen: Screen.reducer,

    appointmentModal: AppointmentModal.reducer,
    companyModal: CompanyModal.reducer,
    containerModal: Container.reducer,
    gallery: Gallery.reducer,
    itemDetails: ItemDetails.reducer,
    itemSearch: ItemSearch.reducer,
    newParty: NewParty.reducer,
    partySearch: PartySearch.reducer,
    taskModal: TaskModal.reducer,
    tripSearch: TripSearch.reducer,

    clientFile: ClientFile.reducer,
    generalInfo: GeneralInfo.reducer,
    poItems: POItems.reducer,
    purchaseOrder: PurchaseOrder.reducer,
    cfShipping: CFShipping.reducer,
    cfTransactions: CFTransactions.reducer,

    campaignDetails: CampaignDetails.reducer,
    crm: CRM.reducer,
    files: Files.reducer,
    financials: Financials.reducer,
    inventory: Inventory.reducer,
    operations: Operations.reducer,
    quickEntry: QuickEntry.reducer,
    quotations: Quotations.reducer,
    reusableCrates: ReusableCrates.reducer,
    scheduling: Scheduling.reducer,
    shipping: Shipping.reducer,
    surveys: Surveys.reducer,
    tasks: Tasks.reducer,
    warehouses: Warehouses.reducer,

    shipmentDetails: ShipmentDetails.reducer,

    managementBillableServices: ManagementBillableServiceConfig.reducer,
    managementCompanies: ManagementCompanies.reducer,
    managementConfiguration: ManagementConfiguration.reducer,
    managementExcelFields: ManagementExcelFields.reducer,
    managementExcelFieldSources: ManagementExcelFieldSources.reducer,
    managementExcelSources: ManagementExcelSources.reducer,
    managementExtendedDims: ManagementExtendedDims.reducer,
    managementItems: ManagementItems.reducer,
    managementPackingTypes: ManagementPackingTypes.reducer,
    managementRoles: ManagementRoles.reducer,
    managementQuote: ManagementQuote.reducer,
    managementResources: ManagementResources.reducer,
    managementResourceDetails: ManagementResourceDetails.reducer,
    managementSupplierRoutes: ManagementSupplierRoutes.reducer,
    managementSupplierCosts: ManagementSupplierCosts.reducer,
    managementSystemUserDetails: ManagementSystemUserDetails.reducer,
    managementSystemUsers: ManagementSystemUsers.reducer,
    managementUsers: ManagementUsers.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
