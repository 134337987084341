import React, { useEffect } from 'react';
import history from '~services/history/history';
import * as ScreenStore from '~store/infra/screen';

import LocalizationKeys from '~enums/localizationKeys';
import { Grid } from '@mui/material';
import { useLocalization } from '@progress/kendo-react-intl';

import AMWTabs from '~components/controls/AMWTabs';
import InventoryItemsTab from './Items/InventoryItemsTab';
import InventoryPackagesTab from './InventoryPackages/InventoryPackagesTab';

import '~css/pages/Inventory.scss';
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';


export enum InventoryTabs {
    Items,
    Packages,
    EmptyCrates
};

type InventoryProps = { tab: number } &
    ScreenStore.ScreenState;

const Inventory: React.FC<InventoryProps> = (props) => {
    const localization = useLocalization();
    const topbarHeight = props.topbarHeight;

    useEffect(() => {
        let element = document.querySelector('#InventoryTabs');
        !!element && element.setAttribute('style', `top: ${topbarHeight}px` );
    }, [topbarHeight]);

    const handleSelect = (value: string) => {
        switch (Number(value)) {
            case InventoryTabs.Items:
                history.push('/inventory/items');
                break;
            case InventoryTabs.Packages:
                history.push('/inventory/packages');
                break;
            case InventoryTabs.EmptyCrates:
                history.push('/inventory/crates');
                break;
            default:
                return;
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <AMWTabs
                    id={'InventoryTabs'}
                    sticky={true}
                    value={`${props.tab}`}
                    onChange={handleSelect}
                    tabs={[
                        {
                            label: localization.toLanguageString(LocalizationKeys.Inventory.Tabs.Items, "%Items"),
                            content: <InventoryItemsTab />,
                            value: `${InventoryTabs.Items}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Inventory.Tabs.Packages, "%Packages"),
                            content: <InventoryPackagesTab isCrate={false} />,
                            value: `${InventoryTabs.Packages}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Inventory.Tabs.EmptyCrates, "%Empty crates"),
                            content: <InventoryPackagesTab isCrate={true} />,
                            value: `${InventoryTabs.EmptyCrates}`,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default connect(
    (state: ApplicationState, ownProps: {tab:number}) : InventoryProps => {
        return {
            ...ownProps,
            ...state.screen,
        };
    },
)(Inventory as any);