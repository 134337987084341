import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { ShowErrorAction } from '~store/infra/errors';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import history from '~services/history/history';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { SortDescriptor } from "@progress/kendo-data-query";

import { DataRequest } from '~models/dataRequests';
import { FilterData, FilterHandler, FilterList } from '~models/filters';
import ClientFileGridItem from '~models/clientFile/clientFileGridItem';
import NewJobData from '~models/clientFile/newJobData';
import { ClientFileRequestResult } from '~models/clientFile/clientFileRequestResult';
import JobControlGridFilters from '~enums/gridFilters/jobControlFilters';


export enum JobQuickFilter {
    Recent,
    My,
    OfficeOpen,
    Open,
    PastDue,
    MostRecent,
    Closed,
    Completed
}

export interface FilesState {
    isLoading: boolean;
    files: Array<ClientFileGridItem>;
    count: number;
    skip: number;
    totalRevenue: number;

    jobCreateStatus: ServerOperationStatus;
    jobReopenStatus: ServerOperationStatus;
    jobDeleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestJobsAction {
    type: 'JOB_CONTROL_REQUEST';
    skip: number;
}
interface ReceiveJobsAction {
    type: 'JOB_CONTROL_RECEIVE';
    files: ClientFileGridItem[];
    count: number;
    totalRevenue: number;
}
interface ReceiveJobsErrorAction {
    type: 'JOB_CONTROL_ERROR';
}

interface ReopenJobRequestAction {
    type: 'JOB_CONTROL_REOPEN_REQUEST'
}
interface ReopenJobSuccessAction {
    type: 'JOB_CONTROL_REOPEN_SUCCESS'
}
interface ReopenJobErrorAction {
    type: 'JOB_CONTROL_REOPEN_ERROR';
}

interface JobCreateStartAction {
    type: 'JOB_CONTROL_CREATE_START';
}
interface JobCreateSendAction {
    type: 'JOB_CONTROL_CREATE_SEND';
}
interface JobCreateSuccessAction {
    type: 'JOB_CONTROL_CREATE_SUCCESS';
}
interface JobCreateCancelAction {
    type: 'JOB_CONTROL_CREATE_CANCEL';
}
interface JobCreateErrorAction {
    type: 'JOB_CONTROL_CREATE_ERROR';
}

interface JobDeleteStartAction {
    type: 'JOB_CONTROL_DELETE_START';
}
interface JobDeleteSendAction {
    type: 'JOB_CONTROL_DELETE_SEND';
}
interface JobDeleteSuccessAction {
    type: 'JOB_CONTROL_DELETE_SUCCESS';
}
interface JobDeleteCancelAction {
    type: 'JOB_CONTROL_DELETE_CANCEL';
}
interface JobDeleteErrorAction {
    type: 'JOB_CONTROL_DELETE_ERROR';
}

type KnownAction =
    RequestJobsAction | ReceiveJobsAction | ReceiveJobsErrorAction
    | ReopenJobRequestAction | ReopenJobSuccessAction | ReopenJobErrorAction
    | JobCreateStartAction | JobCreateSendAction | JobCreateSuccessAction | JobCreateCancelAction | JobCreateErrorAction
    | JobDeleteStartAction | JobDeleteSendAction | JobDeleteSuccessAction | JobDeleteCancelAction | JobDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

export const getJobPromise = (filters: FilterList | JobQuickFilter, showClosed: boolean, skip?: number, pageSize?: number, sorting?: Array<SortDescriptor>) => {
    let url = '/v1/files/';

    if (typeof filters === 'object') {
        url += `search`;

        return FilterHandler.getApiFilterRequestPromise<ClientFileRequestResult>(url, 'POST', filters, skip, pageSize, sorting);
    } else {
        let quickFilter = filters as JobQuickFilter;
        switch (quickFilter) {
            case JobQuickFilter.Recent:
                url += `recent`;
                break;
            case JobQuickFilter.My:
                url += `my`;
                break;
            case JobQuickFilter.OfficeOpen:
                url += `office-open`;
                break;
            case JobQuickFilter.Open:
                url += `open`;
                break;
            case JobQuickFilter.PastDue:
                url += `past-due`;
                break;
            case JobQuickFilter.MostRecent:
                url += `most-recent`;
                break;
            case JobQuickFilter.Closed:
                url += `closed`;
                break;
            case JobQuickFilter.Completed:
                url += `completed`;
                break;
        }
        let params = new URLSearchParams();
        if (skip !== undefined) params.append('skip', `${skip}`);
        if (pageSize !== undefined) params.append('pageSize', `${pageSize}`);
        if (sorting !== undefined && sorting.length > 0) {
            params.append('sortBy', `${sorting[0].field},${sorting[0].dir ?? 'asc' }`);
        }
        params.append('showClosed', `${showClosed}`);
        if (Array.from(params).length > 0) url += `?${params}`;

        return apiClientInstance.fetchRequest<ClientFileRequestResult>(url, 'GET');
    }
};

export const actionCreators = {
    requestFiles: (filters: FilterList | JobQuickFilter, showClosed: boolean, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files && !appState.files.isLoading) {
            let sentFilters;

            if (Number.isInteger(filters)) {
                sentFilters = filters;
            } else {
                let newFilters = {...(filters as FilterList)} as {[key: string]: FilterData};
                let endDateFilter = newFilters[JobControlGridFilters.EndDate];

                if (!!(endDateFilter?.value)) {
                    const dateValue = new Date(new Date(newFilters[JobControlGridFilters.EndDate].value).toISOString());
                    dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                    newFilters[JobControlGridFilters.EndDate] = { value: dateValue };
                }

                sentFilters = newFilters;
            }

            getJobPromise(sentFilters, showClosed, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.JobControl}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.JobControl}-${UserPreferenceKey.Settings}`]: showClosed,
                        [`${UserPreferencePage.JobControl}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.JobControl}-${UserPreferenceKey.PageSize}`]: pageSize
                    }));

                    data.data.forEach((file) => file.createdOn = new Date(file.createdOn));
                    dispatch({ type: 'JOB_CONTROL_RECEIVE', files: data.data, count: data.count, totalRevenue: data.totalRevenue });
                })
                .catch(err => {
                    dispatch({ type: 'JOB_CONTROL_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'JOB_CONTROL_REQUEST', skip: skip, });
        }
    },
    reopenFile: (fileId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files && !appState.files.isLoading) {
            apiClientInstance.fetchRequest<DataRequest<any>>(`/files/${fileId}/reopen`, 'POST')
                .then(data => {
                    dispatch({ type: 'JOB_CONTROL_REOPEN_SUCCESS', });
                })
                .catch(error => {
                    dispatch({ type: 'JOB_CONTROL_REOPEN_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: error.message });
                });

            dispatch({ type: 'JOB_CONTROL_REOPEN_REQUEST', });
        }
    },
    startCreateJob: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files) {
            dispatch({type: 'JOB_CONTROL_CREATE_START'})
        }
    },
    createJob: (data: NewJobData):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files) {
            apiClientInstance.fetchRequest(
                '/v1/files/',
                'POST',
                {
                    ...data,
                }
            )
                .then((newId) => {
                    dispatch({ type: 'JOB_CONTROL_CREATE_SUCCESS' });
                    history.push(`/files/${newId}`);
                })
                .catch((err) => {
                    dispatch({ type: 'JOB_CONTROL_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'JOB_CONTROL_CREATE_SEND' });
        }
    },
    cancelCreateJob: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files) {
            dispatch({type: 'JOB_CONTROL_CREATE_CANCEL'})
        }
    },
    startDeleteJob: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files) {
            dispatch({type: 'JOB_CONTROL_DELETE_START'})
        }
    },
    deleteJob: (fileId: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files) {
            apiClientInstance.fetchRequest(
                `/v1/files/${fileId}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'JOB_CONTROL_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'JOB_CONTROL_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'JOB_CONTROL_DELETE_SEND' });
        }
    },
    cancelDeleteJob: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.files) {
            dispatch({type: 'JOB_CONTROL_DELETE_CANCEL'})
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: FilesState = {
    files: [],
    count: 0,
    isLoading: false,
    skip: 0,
    totalRevenue: 0,
    jobCreateStatus: ServerOperationStatus.NONE,
    jobReopenStatus: ServerOperationStatus.NONE,
    jobDeleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<FilesState> = (state: FilesState | undefined, incomingAction: Action): FilesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'JOB_CONTROL_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'JOB_CONTROL_RECEIVE':
            return {
                ...state,
                files: action.files,
                count: action.count,
                isLoading: false,
                totalRevenue: action.totalRevenue
            };
        case 'JOB_CONTROL_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'JOB_CONTROL_REOPEN_REQUEST':
            return {
                ...state,
                isLoading: true,
                jobReopenStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'JOB_CONTROL_REOPEN_SUCCESS':
            return {
                ...state,
                isLoading: false,
                jobReopenStatus: ServerOperationStatus.SUCCESS,
            };
        case 'JOB_CONTROL_REOPEN_ERROR':
            return {
                ...state,
                isLoading: false,
                jobReopenStatus: ServerOperationStatus.ERROR,
            };

        case 'JOB_CONTROL_CREATE_START':
            return {
                ...state,
                jobCreateStatus: ServerOperationStatus.READY,
            };
        case 'JOB_CONTROL_CREATE_SEND':
            return {
                ...state,
                jobCreateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'JOB_CONTROL_CREATE_CANCEL':
            return {
                ...state,
                jobCreateStatus: ServerOperationStatus.NONE,
            };
        case 'JOB_CONTROL_CREATE_SUCCESS':
            return {
                ...state,
                jobCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'JOB_CONTROL_CREATE_ERROR':
            return {
                ...state,
                jobCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'JOB_CONTROL_DELETE_START':
            return {
                ...state,
                jobDeleteStatus: ServerOperationStatus.READY,
            };
        case 'JOB_CONTROL_DELETE_SEND':
            return {
                ...state,
                jobDeleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'JOB_CONTROL_DELETE_CANCEL':
            return {
                ...state,
                jobDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'JOB_CONTROL_DELETE_SUCCESS':
            return {
                ...state,
                jobDeleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'JOB_CONTROL_DELETE_ERROR':
            return {
                ...state,
                jobDeleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};