import * as React from 'react';
import { connect } from 'react-redux';
import * as ScreenStore from '~store/infra/screen';
import { ApplicationState } from '~store/ApplicationState';

import { Grid } from '@mui/material';
import SideNav from '~components/navigation/SideNav';
import TopBar from '~components/navigation/TopBar';

import '~css/components/layout/Layout.scss';
import DimensionsWrapper from '~components/infra/DimensionsWrapper';
import { AMWUserData } from '~models/user/AMWUserData';


type LayoutOwnProps = {
    children?: React.ReactNode;
    topBarChildren?: React.ReactElement;
} & { userData?: AMWUserData };
type LayoutProps = LayoutOwnProps & {topbarHeight: number} & typeof ScreenStore.actionCreators;

class Layout extends React.PureComponent<LayoutProps> {
    private onResize(width: number, height: number) {
        this.props.updateTopBarHeight(height);
    }

    public render() {
        return (
            <React.Fragment>
                <SideNav userData={this.props.userData} />
                <TopBarWrapper onResize={this.onResize.bind(this)}>
                    {this.props.topBarChildren}
                </TopBarWrapper>

                <Grid style={{top: `${this.props.topbarHeight}px`}} container className="PageContent__container">
                    <Grid item xs={12} container className="PageContent__content">
                        {this.props.children}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps: LayoutOwnProps) => {
        return {
            ...ownProps,
            userData: state.user.userData,
            topbarHeight: state.screen?.topbarHeight ?? 0,
        };
    },
    {
        ...ScreenStore.actionCreators
    },
    (stateProps, dispatchProps) => {
        return {
            ...stateProps,
            ...dispatchProps,
        }
    }
)(Layout);






type WrapperProps = {
    children?: React.ReactNode;
    onResize: (width: number, height: number) => any;
};

function TopBarWrapper(props: Readonly<WrapperProps>) {
    const divRef = React.useRef(null);
    DimensionsWrapper(divRef, props.onResize);

    return (
        <TopBar ref={divRef}>
            {props.children}
        </TopBar>
    );
}