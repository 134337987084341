import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import history from '~services/history/history';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as NewPartyStore from "~store/modals/newParty";
import * as ScreenStore from '~store/infra/screen';
import { handlePartyCreateResult } from "~store/modals/newPartyResultHandler";

import CRMCompaniesGridFields from '~enums/gridFields/crmCompany';
import { NewPartyKeys } from '~enums/newPartyKeys';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import CompanyGridItem from '~models/party/companyGridItem';

import { Grid } from '@mui/material';
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import { Checkbox } from '@progress/kendo-react-inputs';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import CompaniesToolbar from './CompaniesToolbar';
import LocalizationKeys from '~enums/localizationKeys';


type CompaniesTabStateProps = ScreenStore.ScreenState &
    NewPartyStore.NewPartyElementStateProps &
    CRMStore.CompanyTabState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type CompaniesTabDispatchProps = NewPartyStore.NewPartyElementDispatchProps &
    typeof CRMStore.companiesActionCreators &
    GridContainerDispatchProps;

type CompaniesTabProps = CompaniesTabStateProps & CompaniesTabDispatchProps & NewPartyStore.NewPartyElementProps;

type CompaniesTabState = {
    containerWidth: number,
};

const minColumnWidth = {
    [CRMCompaniesGridFields.Code]: 100,
    [CRMCompaniesGridFields.LegalName]: 150,
    [CRMCompaniesGridFields.PartyType]: 100,
    [CRMCompaniesGridFields.Warning]: 170,
    [CRMCompaniesGridFields.BillingInstructions]: 170,
    [CRMCompaniesGridFields.Deactivated]: 80,
    [CRMCompaniesGridFields.IsBadPayer]: 80,
};

class CompaniesTab extends GridContainer<CompaniesTabProps, CompaniesTabState> {
    constructor(props: Readonly<CompaniesTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestCompanies(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#CompaniesGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('CompaniesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: Readonly<CompaniesTabProps>, prevState: Readonly<CompaniesTabState>, snapshot?: any): void {
        handlePartyCreateResult.apply(this, [NewPartyKeys.CRMCompanies, (result) => {
            // TODO
            // history.push(`/companies/${result.partyId}`);
        }]);

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#CompaniesGridContainer .k-grid-header');
            let tabs = document.querySelector('#CRMTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('CompaniesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onNewCompanyClick() {
        this.props.partyCreation.openDialog(NewPartyKeys.CRMCompanies);
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestCompanies(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestCompanies(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let company = event.dataItem as CompanyGridItem;
        history.push(`/companies/${company.partyId}`);
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"companies-code"}
                    field={CRMCompaniesGridFields.Code}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.Code, "%Code")}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.Code) ?? 100}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.Code]} />
            ),
            (
                <GridColumn
                    key={"companies-legalName"}
                    field={CRMCompaniesGridFields.LegalName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.LegalName, "%Legal name")}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.LegalName)}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.LegalName]} />
            ),
            (
                <GridColumn
                    key={"companies-type"}
                    field={CRMCompaniesGridFields.PartyType}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.Type, "%Type")}
                    cell={(
                        (props: GridCellProps) => {
                            const company = props.dataItem as CompanyGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(company.partyType, company.partyType)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.PartyType)}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.PartyType]} />
            ),
            (
                <GridColumn
                    key={"companies-warning"}
                    field={CRMCompaniesGridFields.Warning}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.Warning, "%Warning")}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.Warning)}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.Warning]} />
            ),
            (
                <GridColumn
                    key={"companies-billingInsructions"}
                    field={CRMCompaniesGridFields.BillingInstructions}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.BillingInstructions, "%Billing insructions")}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.BillingInstructions)}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.BillingInstructions]} />
            ),
            (
                <GridColumn
                    key={"companies-deactivated"}
                    field={CRMCompaniesGridFields.Deactivated}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.Deactivated, "%Deactivated")}
                    cell={(
                        (props: GridCellProps) => {
                            const company = props.dataItem as CompanyGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Checkbox checked={company.deactivated} />
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.Deactivated)}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.Deactivated]} />
            ),
            (
                <GridColumn
                    key={"companies-badPayer"}
                    field={CRMCompaniesGridFields.IsBadPayer}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.BadPayer, "%Bad payer")}
                    cell={(
                        (props: GridCellProps) => {
                            const company = props.dataItem as CompanyGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Checkbox checked={company.isBadPayer} />
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMCompaniesGridFields.IsBadPayer)}
                    orderIndex={this.props.columnOrder[CRMCompaniesGridFields.IsBadPayer]} />
            ),
        ];

        return columns;
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} id={'CompaniesGridContainer'}>
                    <AMWGrid
                        sticky={true}
                        scrollable={'scrollable'}

                        data={this.props.companies}
                        total={this.props.count}

                        pageable={{ buttonCount: 4, pageSizes: true }}
                        skip={this.props.skip}
                        pageSize={this.props.pageSize}
                        onPageChange={this.onPageChange.bind(this)}

                        sortable={true}
                        sort={this.props.sorting}
                        onSortChange={this.onSortChange.bind(this)}

                        selectable={{enabled: false, mode:'single'}}

                        reorderable={true}
                        onColumnReorder={this.onColumnReorder.bind(this)}

                        resizable={true}
                        onColumnResize={this.onColumnResize.bind(this)}

                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                    >
                        <GridToolbar>
                            <CompaniesToolbar
                                isGridBusy={this.props.isLoading}
                                filters={this.props.filters}
                                requestData={(filters) => {
                                    this.props.requestCompanies(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                                }}
                                onNewCompanyClick={this.onNewCompanyClick.bind(this)}
                            />
                        </GridToolbar>

                        {this.columns}
                    </AMWGrid>
                </Grid>
            </Grid>
        );
    }
}
registerForLocalization(CompaniesTab);

export default connect(
    (state: ApplicationState) : CompaniesTabStateProps => {
        return {
            pageKey: UserPreferencePage.CRMCompanies,
            ...state.crm?.companies,
            ...state.screen,
            partyCreation: state.newParty,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : CompaniesTabDispatchProps => {
        let crm = bindActionCreators({...CRMStore.companiesActionCreators}, dispatch);
        let users = bindActionCreators({...UsersStore.actionCreators}, dispatch);
        let newParty = bindActionCreators({...NewPartyStore.actionCreators}, dispatch);

        return {
            ...crm,
            ...users,
            partyCreation: newParty
        };
    },
    (stateProps, dispatchProps) : CompaniesTabProps => {
        return {
            ...stateProps,
            ...dispatchProps,
            partyCreation: {
                ...stateProps.partyCreation,
                ...dispatchProps.partyCreation
            }
        }
    },
)(CompaniesTab as any);