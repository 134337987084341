import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import history from '~services/history/history';
import { ApplicationState } from '~store/ApplicationState';
import * as ScreenStore from '~store/infra/screen';
import LocalizationKeys from '~enums/localizationKeys';
import { AMWUserData } from '~models/user/AMWUserData';
import { Grid } from '@mui/material';
import { useLocalization } from '@progress/kendo-react-intl';
import AMWTabs from '~components/controls/AMWTabs';

import '~css/pages/Management.scss';

const CompaniesTab = React.lazy(() => import('./companies/CompaniesTab'));
const SystemUsersTab = React.lazy(() => import('./systemUsers/SystemUsersTab'));
const RolesTab = React.lazy(() => import('./roles/RolesTab'));
const UsersTab = React.lazy(() => import('./users/UsersTab'));
const ResourcesTab = React.lazy(() => import('./resources/ResourcesTab'));
const QuoteTab = React.lazy(() => import('./quote/QuoteTab'));
const PackingTab = React.lazy(() => import('./packing/PackingTab'));
const BillableServicesTab = React.lazy(() => import('./billableService/BillableServicesTab'));
const SupplierCostsTab = React.lazy(() => import('./suppliercosts/SupplierCostsTab'));
const ExcelSourcesTab = React.lazy(() => import('./excelSources/ExcelSourcesTab'));
const ItemConfigTab = React.lazy(() => import('./itemconfig/ItemConfigTab'));
const ExtendedDimsTab = React.lazy(() => import('./extendeddims/ExtendedDimsTab'));
const ConfigurationTab = React.lazy(() => import('./configuration/ConfigurationTab'));

export enum ManagementTabs {
    Companies,
    SystemUsers,
    Roles,
    Users,
    Resources,
    RatesServices,
    AuditTrail,
    Quote,
    Report,
    BulkReport,
    BillableService,
    PackingType,
    SupplierCost,
    ExcelSources,
    ExternalApps,
    MailConfig,
    ItemConfig,
    ExtendedDims,
    Configuration,
    NotificationExclusion,
    NotificationConfig,
    AppSettings,
};

type ManagementProps = { tab: number; userData?: AMWUserData; }
    & ScreenStore.ScreenState;

const Management : React.FC<ManagementProps> = (props) => {
    const localization = useLocalization();
    const topbarHeight = props.topbarHeight;

    useEffect(() => {
        let element = document.querySelector('#ManagementTabs');
        !!element && element.setAttribute('style', `top: ${topbarHeight}px` );
    }, [topbarHeight]);

    const handleTabSelect = (value: string) => {
        switch (Number(value)) {
            case ManagementTabs.Companies:
                history.push('/management/companies');
                break;
            case ManagementTabs.SystemUsers:
                history.push('/management/system-users');
                break;
            case ManagementTabs.Roles:
                history.push('/management/roles');
                break;
            case ManagementTabs.Users:
                history.push('/management/users');
                break;
            case ManagementTabs.Resources:
                history.push('/management/resources');
                break;
            case ManagementTabs.Quote:
                history.push('/management/quote');
                break;
            case ManagementTabs.PackingType:
                history.push('/management/packing');
                break;
            case ManagementTabs.BillableService:
                history.push('/management/billable-services');
                break;
            case ManagementTabs.SupplierCost:
                history.push('/management/supplier-costs');
                break;
            case ManagementTabs.ItemConfig:
                history.push('/management/item-config');
                break;
            case ManagementTabs.ExcelSources:
                history.push('/management/excel-sources');
                break;
            case ManagementTabs.ExtendedDims:
                history.push('/management/extended-dims');
                break;
            case ManagementTabs.Configuration:
                history.push('/management/configuration');
                break;
            default:
                return;
        }
    }

    const openedTab = () => {
        if (!!props.tab) return props.tab;

        // TODO
        // if (props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View))
        //     return CRMTabs.Opportunities;
        // if (props.userData?.hasPermission(UserPermissions.CRM.Companies.View))
        //     return CRMTabs.Companies;
        // if (props.userData?.hasPermission(UserPermissions.CRM.Tasks.View))
        //     return CRMTabs.Tasks;
        // if (props.userData?.hasPermission(UserPermissions.CRM.Appointments.View))
        //     return CRMTabs.Appointments;
        // if (props.userData?.hasPermission(UserPermissions.CRM.Calendar.View))
        //     return CRMTabs.Calendar;
        // if (props.userData?.hasPermission(UserPermissions.CRM.Campaigns.View))
        //     return CRMTabs.Campaigns;
        // if (props.userData?.hasPermission(UserPermissions.CRM.Recent.View))
        //     return CRMTabs.RecentlyAdded;

        return ManagementTabs.Companies;

        // throw new Error("Invalid permission configuration");
    }

    return (
        <Grid container>
            <Grid item xs={12} className={"Management Management__root"}>
                <AMWTabs
                    id={'ManagementTabs'}
                    sticky={true}
                    value={`${openedTab()}`}
                    onChange={handleTabSelect}
                    variant={"scrollable"}
                    scrollButtons={false}
                    tabs={[
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Companies, "%Companies"),
                            value: `${ManagementTabs.Companies}`,
                            content: <CompaniesTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.SystemUsers, "%System users"),
                            value: `${ManagementTabs.SystemUsers}`,
                            content: <SystemUsersTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Roles, "%Roles"),
                            value: `${ManagementTabs.Roles}`,
                            content: <RolesTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Users, "%Users"),
                            value: `${ManagementTabs.Users}`,
                            content: <UsersTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Resources, "%Resources"),
                            value: `${ManagementTabs.Resources}`,
                            content: <ResourcesTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.RatesServices, "%Rates & services"),
                            value: `${ManagementTabs.RatesServices}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.AuditTrail, "%Audit trail"),
                            value: `${ManagementTabs.AuditTrail}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Quote, "%Quote"),
                            value: `${ManagementTabs.Quote}`,
                            content: <QuoteTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Report, "%Report"),
                            value: `${ManagementTabs.Report}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.BulkReport, "%Bulk report"),
                            value: `${ManagementTabs.BulkReport}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.BillableSerivces, "%Billable services"),
                            value: `${ManagementTabs.BillableService}`,
                            content: <BillableServicesTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.PackingTypes, "%Packing types"),
                            value: `${ManagementTabs.PackingType}`,
                            content: <PackingTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.SupplierCost, "%Supplier cost"),
                            value: `${ManagementTabs.SupplierCost}`,
                            content: <SupplierCostsTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.ExcelSources, "%Excel sources"),
                            value: `${ManagementTabs.ExcelSources}`,
                            content: <ExcelSourcesTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.ExternalApps, "%External apps"),
                            value: `${ManagementTabs.ExternalApps}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.MailConfig, "%Mail config"),
                            value: `${ManagementTabs.MailConfig}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.ItemConfig, "%Item config"),
                            value: `${ManagementTabs.ItemConfig}`,
                            content: <ItemConfigTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.ExtendedDims, "%Extended dims"),
                            value: `${ManagementTabs.ExtendedDims}`,
                            content: <ExtendedDimsTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.Configuration, "%Configuration"),
                            value: `${ManagementTabs.Configuration}`,
                            content: <ConfigurationTab />,
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.NotificationExclusion, "%Notification exclusion"),
                            value: `${ManagementTabs.NotificationExclusion}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.NotificationConfig, "%Notification config"),
                            value: `${ManagementTabs.NotificationConfig}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Management.Tabs.AppSettings, "%App settings"),
                            value: `${ManagementTabs.AppSettings}`,
                            content: <React.Fragment />, // TODO
                            // accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState, ownProps: {tab:number}) : ManagementProps => {
        return {
            ...ownProps,
            ...state.screen,
            userData: state.user.userData,
        };
    },
)(Management);