import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import history from '~services/history/history';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import CRMOpportunitiesGridFields from '~enums/gridFields/crmOpportunity';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import OpportunityRecentGridItem from '~models/opportunity/opportunityRecentGridItem';

import {
    GridColumn,
    GridPageChangeEvent,
    GridRowDoubleClickEvent,
    GridSortChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';

import AMWGrid from '~components/datagrid/AMWGrid';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import RecentToolbar from './RecentToolbar';
import LocalizationKeys from '~enums/localizationKeys';


type OpportunitiesStateProps = CRMStore.RecentOpportunitiesState &
    ScreenStore.ScreenState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type OpportunitiesDispatchProps = typeof CRMStore.recentActionCreators &
    GridContainerDispatchProps;

type OpportunitiesTabProps = OpportunitiesStateProps & OpportunitiesDispatchProps;

type OpportunitiesTabState = {
    containerWidth: number,
};

const minColumnWidth = {
    [CRMOpportunitiesGridFields.CreatedOn]: 160,
    [CRMOpportunitiesGridFields.OwnedByName]: 100,
    [CRMOpportunitiesGridFields.LeadName]: 150,
    [CRMOpportunitiesGridFields.Description]: 150,
};

class OpportunitiesTab extends GridContainer<OpportunitiesTabProps, OpportunitiesTabState>  {
    constructor(props: Readonly<OpportunitiesTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestOpportunities(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#OpportunitiesGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        let recenttabs = document.querySelector('#CRMRecentTabs');
        !!element && !!tabs && !!recenttabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight + recenttabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('OpportunitiesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: OpportunitiesTabProps) {
        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#OpportunitiesGridContainer .k-grid-header');
            let tabs = document.querySelector('#CRMTabs');
            let recenttabs = document.querySelector('#CRMRecentTabs');
            !!element && !!tabs && !!recenttabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight + recenttabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('OpportunitiesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestOpportunities(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestOpportunities(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"recentopportunities-createdon"}
                    field={CRMOpportunitiesGridFields.CreatedOn}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Opportunities.CreatedOn, "%Created on")}
                    format="{0:g}"
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.CreatedOn) ?? 160}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.CreatedOn]} />
            ),
            (
                <GridColumn
                    key={"recentcontacts-ownedby"}
                    field={CRMOpportunitiesGridFields.OwnedByName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Opportunities.OwnedBy, "%Owned by")}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.OwnedByName)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.OwnedByName]} />
            ),
            (
                <GridColumn
                    key={"recentopportunities-partyname"}
                    field={CRMOpportunitiesGridFields.LeadName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Opportunities.CompanyName, "%Company name")}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.LeadName)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.LeadName]} />
            ),
            (
                <GridColumn
                    key={"recentopportunities-description"}
                    field={CRMOpportunitiesGridFields.Description}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Opportunities.Description, "%Description")}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.Description)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.Description]} />
            ),
        ];

        return columns;
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let opportunity = event.dataItem as OpportunityRecentGridItem;
        history.push(`/opportunities/${opportunity.opportunityId}`);
    }

    render() {
        let gridData = this.props.data;

        let columns = this.columns;

        return (
            <div id={'OpportunitiesGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={gridData}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}

                    onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                >
                    <GridToolbar>
                        <RecentToolbar
                            isGridBusy={this.props.isLoading}
                            filters={this.props.filters}
                            requestData={(filters) => {
                                this.props.requestOpportunities(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                            }}
                        />
                    </GridToolbar>

                    {columns}
                </AMWGrid>
            </div>
        );
    }
}

registerForLocalization(OpportunitiesTab as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) : OpportunitiesStateProps => {
        return {
            pageKey: UserPreferencePage.RecentOpportunities,
            ...state.crm.recent.opportunities,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : OpportunitiesDispatchProps => {
        let crmActions = bindActionCreators({...CRMStore.recentActionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...crmActions,
            ...usersActions
        };
    },
)(OpportunitiesTab as any);