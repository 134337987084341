import * as React from 'react';
import { connect } from 'react-redux';
import history from '~services/history/history';
import { ApplicationState } from '~store/ApplicationState';
import * as UserStore from '~store/auth/user';
import Preloader from '../infra/Preloader';

type AuthCallbackProps = UserStore.UserState &
    typeof UserStore.actionCreators;

class AuthCallback extends React.Component<AuthCallbackProps> {

    componentDidMount() {
        console.log('auth callback mounted');
        // if (this.props.error) {
        //     history.push('/');
        //     return;
        // }

        this.__redirectByPermissions();

        // if (this.props.userData === undefined) {
        //     this.props.requestUserData();
        // } else {
        //     this.__redirectByPermissions();
        // }
    }

    componentDidUpdate(prevProps:AuthCallbackProps) {
        // if(!equal(prevProps.userData, this.props.userData)) {
        //     this.__redirectByPermissions();
        // }
    }

    __redirectByPermissions() {
        //TODO
        history.push('/files');
    }

    render() {
        return <Preloader />;
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.user,
        };
    },
    {
        ...UserStore.actionCreators
    }
)(AuthCallback as any);