import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import history from '~services/history/history';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as QuotationsStore from '~store/pages/mainViews/quotations';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import QuotationsGridFields from '~enums/gridFields/quotations';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import NewQuotationData from '~models/quotation/newQuotationData';
import QuotationGridItem from '~models/quotation/quotationGridItem';
import { checkboxColumnWidth } from '~enums/constants';

import { Grid } from '@mui/material';
import { getter } from "@progress/kendo-react-common";
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable, SELECTED_FIELD } from '~components/datagrid/types';
import ConfirmationDialog from '~components/infra/ConfirmationDialog';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import QuotationsToolbar from './QuotationsToolbar';
import NewQuotationModal from './NewQuotationModal';
import LocalizationKeys from '~enums/localizationKeys';


const DATA_ITEM_KEY: string = "quotationId";
const idGetter = getter(DATA_ITEM_KEY);

type QuotationsTabStateProps = ScreenStore.ScreenState
    & QuotationsStore.QuotationsTabState
    & GridFilterable
    & GridPageable
    & GridSortable
    & GridContainerStateProps;

type QuotationsTabDispatchProps = typeof QuotationsStore.actionCreators
    & GridContainerDispatchProps;

type QuotationsTabProps = QuotationsTabStateProps
    & QuotationsTabDispatchProps;

type QuotationsTabState = {
    containerWidth: number;
    selectedId?: number;
};

const minColumnWidth = {
    [SELECTED_FIELD]: 50,
    [QuotationsGridFields.UniqueId]: 100,
    [QuotationsGridFields.Description]: 150,
    [QuotationsGridFields.TotalAfterTax]: 125,
    [QuotationsGridFields.CreatedOn]: 125,
    [QuotationsGridFields.ClientRef]: 150,
    [QuotationsGridFields.JobId]: 100,
    [QuotationsGridFields.ProjectNr]: 100,
    [QuotationsGridFields.ClientName]: 150,
    [QuotationsGridFields.ClientCode]: 120,
    [QuotationsGridFields.CreatedByName]: 100,
    [QuotationsGridFields.ShipperCity]: 100,
    [QuotationsGridFields.ShipperCountry]: 100,
    [QuotationsGridFields.ConsigneeCity]: 100,
    [QuotationsGridFields.ConsigneeCountry]: 100,
};

class QuotationsTab extends GridContainer<QuotationsTabProps, QuotationsTabState> {
    constructor(props: QuotationsTabProps) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestQuotations(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#QuotationsGridContainer .k-grid-header');
        let tabs = document.querySelector('#QuotationsTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('QuotationsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: QuotationsTabProps) {
        if (!equal(prevProps.quotations, this.props.quotations)) {
            this.setState({ selectedId: undefined, });
        }

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#QuotationsGridContainer .k-grid-header');
            let tabs = document.querySelector('#QuotationsTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }

        if (prevProps.quotationDeleteStatus !== this.props.quotationDeleteStatus && this.props.quotationDeleteStatus === ServerOperationStatus.SUCCESS) {
            this.props.requestQuotations(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('QuotationsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onNewQuoteClick() {
        this.props.startCreateQuotations();
    }

    private onNewQuotationSubmit = (data: NewQuotationData) => {
        this.props.createQuotation(data);
    }

    private onNewQuoteCancel() {
        this.props.cancelCreateQuotations();
    }

    private onCloneQuoteClick() {
        !!this.state.selectedId && this.props.cloneQuotations(this.state.selectedId);
    }

    private onDeleteQuoteClick = () => {
        this.props.startDeleteQuotations();
    }

    private onDeleteQuoteSubmit = () => {
        !!this.state.selectedId && this.props.deleteQuotations(this.state.selectedId);
    }

    private onDeleteQuoteClose = () => {
        this.props.cancelDeleteQuotations();
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestQuotations(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestQuotations(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let quote = event.dataItem as QuotationGridItem;
        history.push(`/quotations/${quote.quotationId}`);
    }

    private onRowSelect(event: GridSelectionChangeEvent) {
        if (event.nativeEvent.target.tagName === 'A') {
            return false;
        }

        this.setState({
            selectedId: GridUtils.getSelectedIds(event, !!this.state.selectedId ? [this.state.selectedId] : [], DATA_ITEM_KEY)[0],
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"quotations-check"}
                    field={SELECTED_FIELD}
                    width={checkboxColumnWidth}
                    headerClassName={'grid__header--empty'}
                    className={'grid__cell--checkbox'}
                    reorderable={false}
                    orderIndex={0}
                    resizable={false}
                />
            ),
            (
                <GridColumn
                    key={"quotations-number"}
                    field={QuotationsGridFields.UniqueId}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.UniqueId, "%Quotation #")}
                    cell={(
                        (props: GridCellProps) => {
                            const quote = props.dataItem as QuotationGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/quotations/${quote.quotationId}`}>{quote.uniqueId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(QuotationsGridFields.UniqueId) ?? 100}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.UniqueId]}
                />
            ),
            (
                <GridColumn
                    key={"quotations-description"}
                    field={QuotationsGridFields.Description}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.Description, "%Description")}
                    width={this.calculateColumnWidth(QuotationsGridFields.Description)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.Description]} />
            ),
            (
                <GridColumn
                    key={"quotations-total"}
                    field={QuotationsGridFields.TotalAfterTax}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.Total, "%Total")}
                    format="{0:n2}"
                    width={this.calculateColumnWidth(QuotationsGridFields.TotalAfterTax)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.TotalAfterTax]} />
            ),
            (
                <GridColumn
                    key={"quotations-created"}
                    field={QuotationsGridFields.CreatedOn}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.Created, "%Created")}
                    format="{0:d}"
                    width={this.calculateColumnWidth(QuotationsGridFields.CreatedOn) ?? 125}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.CreatedOn]} />
            ),
            (
                <GridColumn
                    key={"quotations-clientRef"}
                    field={QuotationsGridFields.ClientRef}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ClientRef, "%Client ref")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ClientRef)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ClientRef]} />
            ),
            (
                <GridColumn
                    key={"quotations-job"}
                    field={QuotationsGridFields.JobId}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.Job, "%Job")}
                    cell={(
                        (props: GridCellProps) => {
                            const quote = props.dataItem as QuotationGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/files/${quote.clientFileId}`}>{quote.jobId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(QuotationsGridFields.JobId)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.JobId]} />
            ),
            (
                <GridColumn
                    key={"quotations-project"}
                    field={QuotationsGridFields.ProjectNr}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.Project, "%Project")}
                    cell={(
                        (props: GridCellProps) => {
                            const quote = props.dataItem as QuotationGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/projects/${quote.projectId}`}>{quote.projectNr}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(QuotationsGridFields.ProjectNr)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ProjectNr]} />
            ),
            (
                <GridColumn
                    key={"quotations-client"}
                    field={QuotationsGridFields.ClientName}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ClientName, "%Client name")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ClientName)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ClientName]} />
            ),
            (
                <GridColumn
                    key={"quotations-clientCode"}
                    field={QuotationsGridFields.ClientCode}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ClientCode, "%Client code")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ClientCode)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ClientCode]} />
            ),
            (
                <GridColumn
                    key={"quotations-createdBy"}
                    field={QuotationsGridFields.CreatedByName}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.CreatedBy, "%Created by")}
                    width={this.calculateColumnWidth(QuotationsGridFields.CreatedByName)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.CreatedByName]} />
            ),
            (
                <GridColumn
                    key={"quotations-shipperCity"}
                    field={QuotationsGridFields.ShipperCity}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ShipperCity, "%Shipper city")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ShipperCity)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ShipperCity]} />
            ),
            (
                <GridColumn
                    key={"quotations-shipperCountry"}
                    field={QuotationsGridFields.ShipperCountry}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ShipperCountry, "%Shipper country")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ShipperCountry)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ShipperCountry]} />
            ),
            (
                <GridColumn
                    key={"quotations-consigneeCity"}
                    field={QuotationsGridFields.ConsigneeCity}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ConsigneeCity, "%Consignee city")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ConsigneeCity)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ConsigneeCity]} />
            ),
            (
                <GridColumn
                    key={"quotations-consigneeCountry"}
                    field={QuotationsGridFields.ConsigneeCountry}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.ConsigneeCountry, "%Consignee country")}
                    width={this.calculateColumnWidth(QuotationsGridFields.ConsigneeCountry)}
                    orderIndex={this.props.columnOrder[QuotationsGridFields.ConsigneeCountry]} />
            ),
        ];

        return columns;
    }

    render() {
        const localization = provideLocalizationService(this);

        let isGridBusy = this.props.isLoading ||
            this.props.quotationCreateStatus === ServerOperationStatus.INPROGRESS ||
            this.props.quotationCloneStatus === ServerOperationStatus.INPROGRESS ||
            this.props.quotationDeleteStatus === ServerOperationStatus.INPROGRESS;

        return (
            <Grid id={'QuotationsGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={this.props.quotations.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: this.state.selectedId === idGetter(item),
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    selectedField={SELECTED_FIELD}
                    selectable={{enabled: true, mode:'single'}}
                    onSelectionChange={this.onRowSelect.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}

                    onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                >
                    <GridToolbar>
                        <QuotationsToolbar
                            selectedItem={this.state.selectedId}
                            isGridBusy={isGridBusy}
                            filters={this.props.filters}
                            requestData={(filters) => {
                                this.props.requestQuotations(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                            }}
                            onNewQuoteClick={this.onNewQuoteClick.bind(this)}
                            onCloneQuoteClick={this.onCloneQuoteClick.bind(this)}
                            onDeleteQuoteClick={this.onDeleteQuoteClick.bind(this)}
                        />
                    </GridToolbar>

                    {this.columns}
                </AMWGrid>

                <ConfirmationDialog
                    open={this.props.quotationDeleteStatus !== ServerOperationStatus.NONE &&
                        this.props.quotationDeleteStatus !== ServerOperationStatus.SUCCESS}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Delete.Title, "%Delete quotation")}
                    text={localization.toLanguageString(LocalizationKeys.Quotations.Delete.Text, "%Selected quotation will be permanently deleted. Proceed?")}
                    onSubmit={this.onDeleteQuoteSubmit.bind(this)}
                    onCancel={this.onDeleteQuoteClose.bind(this)}
                />

                {this.props.quotationCreateStatus !== ServerOperationStatus.NONE &&
                    this.props.quotationCreateStatus !== ServerOperationStatus.SUCCESS &&
                (
                    <NewQuotationModal
                        open={true}
                        onSubmit={this.onNewQuotationSubmit.bind(this)}
                        onClose={this.onNewQuoteCancel.bind(this)}
                        createStatus={this.props.quotationCreateStatus}
                    />
                )}
            </Grid>
        );
    }
}
registerForLocalization(QuotationsTab as any);

export default connect(
    (state: ApplicationState) : QuotationsTabStateProps => {
        return {
            pageKey: UserPreferencePage.Quotations,
            ...state.quotations?.quotations,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.Quotations}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.Quotations}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.Quotations}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.Quotations}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.Quotations}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : QuotationsTabDispatchProps => {
        let quotationActions = bindActionCreators({...QuotationsStore.actionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...quotationActions,
            ...usersActions,
        };
    },
)(QuotationsTab as any);