import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import history from '~services/history/history';
import * as ScreenStore from '~store/infra/screen';
import { ApplicationState } from '~store/ApplicationState';

import LocalizationKeys from '~enums/localizationKeys';
import { UserPermissions } from '~enums/userPermissions';
import { AMWUserData } from '~models/user/AMWUserData';

import { Grid } from '@mui/material';
import { useLocalization } from '@progress/kendo-react-intl';

import AMWTabs from '~components/controls/AMWTabs';
import AppointmentsTab from './Appointments/AppointmentsTab';
import Calendar from './Calendar/Calendar';
import CampaignsTab from './Campaigns/CampaignsTab';
import CompaniesTab from './Companies/CompaniesTab';
import OpportunitiesTab from './Opportunities/OpportunitiesTab';
import RecentTab from './Recent/RecentTab';
import TasksTab from './Tasks/TasksTab';


export enum CRMTabs {
    Opportunities,
    Companies,
    Tasks,
    Appointments,
    Calendar,
    Campaigns,
    RecentlyAdded
};

export enum CRMRecentTabs {
    Companies,
    Contacts,
    Opportunities,
    Tasks,
    Appointments,
    Communications,
    Documents,
};

type CRMOwnProps = { tab?: number, recentTab?: number, };
type CRMStateProps = { userData?: AMWUserData; }
    & ScreenStore.ScreenState;
type CRMProps = CRMOwnProps & CRMStateProps;

const CRM : React.FC<CRMProps> = (props) => {
    const localization = useLocalization();
    const topbarHeight = props.topbarHeight;

    useEffect(() => {
        let element = document.querySelector('#CRMTabs');
        !!element && element.setAttribute('style', `top: ${topbarHeight}px` );
    }, [topbarHeight]);

    const handleTabSelect = (value: string) => {
        switch (Number(value)) {
            case CRMTabs.Opportunities:
                history.push('/crm/opportunities');
                break;
            case CRMTabs.Companies:
                history.push('/crm/companies');
                break;
            case CRMTabs.Tasks:
                history.push('/crm/tasks');
                break;
            case CRMTabs.Appointments:
                history.push('/crm/appointments');
                break;
            case CRMTabs.Calendar:
                history.push('/crm/calendar');
                break;
            case CRMTabs.Campaigns:
                history.push('/crm/campaigns');
                break;
            case CRMTabs.RecentlyAdded:
                history.push('/crm/recent');
                break;
            default:
                return;
        }
    }

    const handleRecentTabSelect = (value: string) => {
        switch (Number(value)) {
            case CRMRecentTabs.Companies:
                history.push('/crm/recent/companies');
                break;
            case CRMRecentTabs.Contacts:
                history.push('/crm/recent/contacts');
                break;
            case CRMRecentTabs.Opportunities:
                history.push('/crm/recent/opportunities');
                break;
            case CRMRecentTabs.Tasks:
                history.push('/crm/recent/tasks');
                break;
            case CRMRecentTabs.Appointments:
                history.push('/crm/recent/appointments');
                break;
            case CRMRecentTabs.Documents:
                history.push('/crm/recent/documents');
                break;
            case CRMRecentTabs.Communications:
                history.push('/crm/recent/communications');
                break;
            default:
                return;
        }
    }

    const openedTab = () => {
        if (!!props.tab) return props.tab;

        if (props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View))
            return CRMTabs.Opportunities;
        if (props.userData?.hasPermission(UserPermissions.CRM.Companies.View))
            return CRMTabs.Companies;
        if (props.userData?.hasPermission(UserPermissions.CRM.Tasks.View))
            return CRMTabs.Tasks;
        if (props.userData?.hasPermission(UserPermissions.CRM.Appointments.View))
            return CRMTabs.Appointments;
        if (props.userData?.hasPermission(UserPermissions.CRM.Calendar.View))
            return CRMTabs.Calendar;
        if (props.userData?.hasPermission(UserPermissions.CRM.Campaigns.View))
            return CRMTabs.Campaigns;
        if (props.userData?.hasPermission(UserPermissions.CRM.Recent.View))
            return CRMTabs.RecentlyAdded;

        throw new Error("Invalid permission configuration");
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <AMWTabs
                    id={'CRMTabs'}
                    sticky={true}
                    value={`${openedTab()}`}
                    onChange={handleTabSelect}
                    tabs={[
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.Opportunities, "%Opportunities"),
                            value: `${CRMTabs.Opportunities}`,
                            content: <OpportunitiesTab />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.Companies, "%Companies"),
                            value: `${CRMTabs.Companies}`,
                            content: <CompaniesTab />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Companies.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.Tasks, "%Tasks"),
                            value: `${CRMTabs.Tasks}`,
                            content: <TasksTab />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Tasks.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.Appointments, "%Appointments"),
                            value: `${CRMTabs.Appointments}`,
                            content: <AppointmentsTab />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Appointments.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.Calendar, "%Calendar"),
                            value: `${CRMTabs.Calendar}`,
                            content: <Calendar />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Calendar.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.Campaigns, "%Campaign"),
                            value: `${CRMTabs.Campaigns}`,
                            content: <CampaignsTab />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Campaigns.View),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Tabs.RecentlyAdded, "%Recently added"),
                            value: `${CRMTabs.RecentlyAdded}`,
                            content: <RecentTab
                                selectedTab={props.recentTab ?? 0}
                                onTabSelect={handleRecentTabSelect}
                                topbarHeight={props.topbarHeight}
                            />,
                            accessible: props.userData?.hasPermission(UserPermissions.CRM.Recent.View),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) : CRMStateProps => {
        return {
            ...state.screen,
            userData: state.user.userData,
        };
    },
    null,
    (stateProps, dispatchProps, ownProps: CRMOwnProps) : CRMProps => {
        return {
            ...stateProps,
            ...ownProps,
        };
    },
)(CRM);