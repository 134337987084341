import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import history from '~services/history/history';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import CFShipmentGridItem from '~models/shipment/cfShipmentGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler } from '~models/filters';
import { ShowErrorAction } from '~store/infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import ShipmentsGridFilters from '~enums/gridFilters/shipmentFilters';


export interface CFShippingState {
    isLoading: boolean;
    shipments: Array<CFShipmentGridItem>;
    count: number;
    skip: number;
    shipmentCreateStatus: ServerOperationStatus;
    shipmentDeleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ShippingRequestAction {
    type: 'CF_SHIPPING_REQUEST';
    skip: number;
}
interface ShippingReceiveAction {
    type: 'CF_SHIPPING_RECEIVE';
    shipments: CFShipmentGridItem[];
    count: number;
}
interface ShippingErrorAction {
    type: 'CF_SHIPPING_ERROR';
}

interface ShippingCreateSendAction {
    type: 'CF_SHIPPING_CREATE_SEND';
}
interface ShippingCreateSuccessAction {
    type: 'CF_SHIPPING_CREATE_SUCCESS';
}
interface ShippingCreateErrorAction {
    type: 'CF_SHIPPING_CREATE_ERROR'
}

interface ShippingDeleteStartAction {
    type: 'CF_SHIPPING_DELETE_START';
}
interface ShippingDeleteSendAction {
    type: 'CF_SHIPPING_DELETE_SEND';
}
interface ShippingDeleteSuccessAction {
    type: 'CF_SHIPPING_DELETE_SUCCESS';
}
interface ShippingDeleteCancelAction {
    type: 'CF_SHIPPING_DELETE_CANCEL';
}
interface ShippingDeleteErrorAction {
    type: 'CF_SHIPPING_DELETE_ERROR';
}


type KnownAction = ShippingRequestAction | ShippingReceiveAction | ShippingErrorAction
    | ShippingCreateSendAction | ShippingCreateSuccessAction | ShippingCreateErrorAction
    | ShippingDeleteStartAction | ShippingDeleteSendAction | ShippingDeleteSuccessAction | ShippingDeleteCancelAction | ShippingDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestShipments: (fileId: number, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.cfShipping && !appState.cfShipping.isLoading) {
            let cfFilters = {
                [ShipmentsGridFilters.FileId]: {value: fileId},
                [ShipmentsGridFilters.Groupages]: {value: false},
            };

            FilterHandler.getApiFilterRequestPromise<DataRequest<CFShipmentGridItem>>('/v1/shipping/search', 'POST', cfFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ClientFileShipping}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ClientFileShipping}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((file) => {
                        !!file.eta && (file.eta = new Date(file.eta));
                        !!file.etd && (file.etd = new Date(file.etd));
                    });
                    dispatch({ type: 'CF_SHIPPING_RECEIVE', shipments: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CF_SHIPPING_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CF_SHIPPING_REQUEST', skip: skip, });
        }
    },
    createShipment: (fileId: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.cfShipping) {
            apiClientInstance.fetchRequest(
                '/v1/shipping/',
                'POST',
                { clientFileId: fileId }
            )
                .then((newId) => {
                    history.push(`/files/${fileId}/shipments/${newId}`);

                    dispatch({ type: 'CF_SHIPPING_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'CF_SHIPPING_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CF_SHIPPING_CREATE_SEND' });
        }
    },
    startDeleteShipments: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.cfShipping) {
            dispatch({ type: 'CF_SHIPPING_DELETE_START' });
        }
    },
    deleteShipments: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.cfShipping) {
            apiClientInstance.fetchRequest(
                `/v1/shipping/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'CF_SHIPPING_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'CF_SHIPPING_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CF_SHIPPING_CREATE_SEND' });
        }
    },
    cancelDeleteShipments: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.cfShipping) {
            dispatch({ type: 'CF_SHIPPING_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: CFShippingState = {
    isLoading: false,
    shipments: [],
    count: 0,
    skip: 0,
    shipmentCreateStatus: ServerOperationStatus.NONE,
    shipmentDeleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<CFShippingState> = (state: CFShippingState | undefined, incomingAction: Action): CFShippingState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CF_SHIPPING_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'CF_SHIPPING_RECEIVE':
            return {
                ...state,
                shipments: action.shipments,
                count: action.count,
                isLoading: false,
            };
        case 'CF_SHIPPING_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'CF_SHIPPING_CREATE_SEND':
            return {
                ...state,
                isLoading: true,
                shipmentCreateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'CF_SHIPPING_CREATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                shipmentCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'CF_SHIPPING_CREATE_ERROR':
            return {
                ...state,
                isLoading: false,
                shipmentCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'CF_SHIPPING_DELETE_START':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.READY,
            };
        case 'CF_SHIPPING_DELETE_SEND':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'CF_SHIPPING_DELETE_CANCEL':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'CF_SHIPPING_DELETE_SUCCESS':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'CF_SHIPPING_DELETE_ERROR':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};