import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from '~services/history/history';
import GridUtils from '~src/utils/gridUtils';
import equal from 'fast-deep-equal/react';
import { ApplicationState } from '~store/ApplicationState';
import * as InventoryStore from '~store/pages/mainViews/inventory';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import InventoryPackagesGridFields from '~enums/gridFields/inventoryPackages';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { Grid } from '@mui/material';
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Checkbox } from '@progress/kendo-react-inputs';
import { provideLocalizationService, } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import PackageInventoryGridItem from '~models/package/packageInventory';
import InventoryPackagesToolbar from './InventoryPackagesToolbar';
import LocalizationKeys from '~enums/localizationKeys';


type InventoryPackagesStateProps = {isCrate: boolean} &
    ScreenStore.ScreenState &
    InventoryStore.InventoryPackagesState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type InventoryPackagesDispatchProps = typeof InventoryStore.actionCreators &
    GridContainerDispatchProps;

type InventoryPackagesTabProps = InventoryPackagesStateProps &
    InventoryPackagesDispatchProps;

type InventoryPackagesTabState = {
    containerWidth: number,
};

const minColumnWidth = {
    [InventoryPackagesGridFields.UniqueId]: 125,
    [InventoryPackagesGridFields.LabelNumber]: 125,
    [InventoryPackagesGridFields.PackageType]: 120,
    [InventoryPackagesGridFields.IsCrate]: 80,
    [InventoryPackagesGridFields.Location]: 120,
    [InventoryPackagesGridFields.DateIn]: 120,
    [InventoryPackagesGridFields.DateOut]: 120,
};

class InventoryPackagesTab extends GridContainer<InventoryPackagesTabProps, InventoryPackagesTabState> {
    constructor(props: InventoryPackagesTabProps) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestPackages(this.props.filters, this.props.isCrate, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#PackagesGridContainer .k-grid-header');
        let tabs = document.querySelector('#InventoryTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('PackagesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: InventoryPackagesTabProps) {
        if (prevProps.isCrate !== this.props.isCrate) {
            this.props.requestPackages(this.props.filters, this.props.isCrate, this.props.skip, this.props.pageSize, this.props.sorting);
        }

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#PackagesGridContainer .k-grid-header');
            let tabs = document.querySelector('#InventoryTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('PackagesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestPackages(this.props.filters, this.props.isCrate, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestPackages(this.props.filters, this.props.isCrate, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let pack = event.dataItem as PackageInventoryGridItem;
        history.push(`/item/${pack.packageId}`);
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"inventory-packageNr"}
                    field={InventoryPackagesGridFields.UniqueId}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.PackageNr, "%Package #")}
                    cell={(
                        (props: GridCellProps) => {
                            const pack = props.dataItem as PackageInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/packages/${pack.packageId}`}>{pack.uniqueId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.UniqueId) ?? 125}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.UniqueId]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-labelNr"}
                    field={InventoryPackagesGridFields.LabelNumber}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.LabelNumber, "%Label #")}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.LabelNumber)}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.LabelNumber]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-packageType"}
                    field={InventoryPackagesGridFields.PackageType}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.PackageType, "%Type")}
                    cell={(
                        (props: GridCellProps) => {
                            const pack = props.dataItem as PackageInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(pack.packageType, pack.packageType)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.PackageType)}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.PackageType]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-iscrate"}
                    field={InventoryPackagesGridFields.IsCrate}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.IsCrate, "%Is crate")}
                    cell={(
                        (props: GridCellProps) => {
                            const pack = props.dataItem as PackageInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Checkbox checked={pack.isCrate} />
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.IsCrate)}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.IsCrate]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-location"}
                    field={InventoryPackagesGridFields.Location}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.Location, "%Location")}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.Location)}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.Location]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-datein"}
                    field={InventoryPackagesGridFields.DateIn}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.DateIn, "%Date in")}
                    format={'{0:d}'}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.DateIn ?? 120)}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.DateIn]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-dateout"}
                    field={InventoryPackagesGridFields.DateOut}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Grid.DateOut, "%Date out")}
                    format={'{0:d}'}
                    width={this.calculateColumnWidth(InventoryPackagesGridFields.DateOut ?? 120)}
                    orderIndex={this.props.columnOrder[InventoryPackagesGridFields.DateOut]}
                />
            ),
        ];

        return columns;
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} id={'PackagesGridContainer'}>
                    <AMWGrid
                        sticky={true}
                        scrollable={'scrollable'}

                        data={this.props.packages}
                        total={this.props.count}

                        pageable={{ buttonCount: 4, pageSizes: true }}
                        skip={this.props.skip}
                        pageSize={this.props.pageSize}
                        onPageChange={this.onPageChange.bind(this)}

                        sortable={true}
                        sort={this.props.sorting}
                        onSortChange={this.onSortChange.bind(this)}

                        reorderable={true}
                        onColumnReorder={this.onColumnReorder.bind(this)}

                        resizable={true}
                        onColumnResize={this.onColumnResize.bind(this)}

                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                    >
                        <GridToolbar>
                            <InventoryPackagesToolbar
                                isGridBusy={this.props.isLoading}
                                filters={this.props.filters}
                                requestData={(filters) => {
                                    this.props.requestPackages(filters, this.props.isCrate, this.props.skip, this.props.pageSize, this.props.sorting);
                                }}
                            />
                        </GridToolbar>

                        {this.columns}
                    </AMWGrid>
                </Grid>
            </Grid>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps: {isCrate: boolean}) : InventoryPackagesStateProps => {
        return ownProps.isCrate ? {
            pageKey: UserPreferencePage.InventoryCrates,
            ...ownProps,
            ...state.inventory.crates,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.InventoryCrates}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.InventoryCrates}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.InventoryCrates}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.InventoryCrates}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.InventoryCrates}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        } : {
            pageKey: UserPreferencePage.InventoryPackages,
            ...ownProps,
            ...state.inventory.packages,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.InventoryPackages}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.InventoryPackages}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.InventoryPackages}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.InventoryPackages}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.InventoryPackages}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : InventoryPackagesDispatchProps => {
        let inventoryActions = bindActionCreators({...InventoryStore.actionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...inventoryActions,
            ...usersActions
        };
    },
)(InventoryPackagesTab as any);