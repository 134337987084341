import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import history from '~services/history/history';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import { checkboxColumnWidth } from '~enums/constants';
import CRMCampaignsGridFields from '~enums/gridFields/crmCampaigns';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import CampaignGridItem from '~models/campaign/campaignGridItem';

import { Grid } from '@mui/material';
import { getter } from "@progress/kendo-react-common";
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable, SELECTED_FIELD } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import NewCampaignModal from './NewCampaignModal';
import CampaignsToolbar from './CampaignsToolbar';
import LocalizationKeys from '~enums/localizationKeys';


const DATA_ITEM_KEY: string = "campaignId";
const idGetter = getter(DATA_ITEM_KEY);

type CampaignsTabStateProps = ScreenStore.ScreenState
    & CRMStore.CampaignTabState
    & GridFilterable
    & GridPageable
    & GridSortable
    & GridContainerStateProps;

type CampaignsTabDispatchProps = typeof CRMStore.campaignsActionCreators &
    GridContainerDispatchProps;

type CampaignsTabProps = CampaignsTabStateProps & CampaignsTabDispatchProps;

type CampaignsTabState = {
    containerWidth: number;
    selectedId?: number;
};

const minColumnWidth = {
    [SELECTED_FIELD]: 50,
    [CRMCampaignsGridFields.CampaignName]: 120,
    [CRMCampaignsGridFields.Description]: 150,
    [CRMCampaignsGridFields.CampaignType]: 100,
    [CRMCampaignsGridFields.OwnedByName]: 100,
    [CRMCampaignsGridFields.CreatedOn]: 160,
    [CRMCampaignsGridFields.Status]: 100,
    [CRMCampaignsGridFields.ExecutionDate]: 160,
};

class CampaignsTab extends GridContainer<CampaignsTabProps, CampaignsTabState> {
    constructor(props: Readonly<CampaignsTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestCampaigns(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#CampaignsGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('CampaignsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: Readonly<CampaignsTabProps>, prevState: Readonly<CampaignsTabState>, snapshot?: any): void {
        if (!equal(prevProps.campaigns, this.props.campaigns)) {
            this.setState({ selectedId: undefined, });
        }

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#CampaignsGridContainer .k-grid-header');
            let tabs = document.querySelector('#CRMTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('CampaignsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onNewCampaignClick() {
        this.props.startCreateCampaign();
    }

    private onNewCampaignSubmit = (name: string) => {
        this.props.createCampaign(name);
    }

    private onNewCampaignCancel() {
        this.props.cancelCreateCampaign();
    }

    private onCloneCampaignClick() {
        !!this.state.selectedId && this.props.cloneCampaign(this.state.selectedId);
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestCampaigns(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestCampaigns(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let campaign = event.dataItem as CampaignGridItem;
        history.push(`/crm/campaigns/${campaign.campaignId}`);
    }

    private onRowSelect(event: GridSelectionChangeEvent) {
        if (event.nativeEvent.target.tagName === 'A') {
            return false;
        }

        this.setState({
            selectedId: GridUtils.getSelectedIds(event, !!this.state.selectedId ? [this.state.selectedId] : [], DATA_ITEM_KEY)[0],
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"campaigns-check"}
                    field={SELECTED_FIELD}
                    width={checkboxColumnWidth}
                    headerClassName={'grid__header--empty'}
                    className={'grid__cell--checkbox'}
                    reorderable={false}
                    orderIndex={0}
                    resizable={false}
                />
            ),
            (
                <GridColumn
                    key={"campaigns-name"}
                    field={CRMCampaignsGridFields.CampaignName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.Name, "%Campaign name")}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.CampaignName)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.CampaignName]} />
            ),
            (
                <GridColumn
                    key={"campaigns-Description"}
                    field={CRMCampaignsGridFields.Description}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.Description, "%Description")}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.Description)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.Description]} />
            ),
            (
                <GridColumn
                    key={"campaigns-type"}
                    field={CRMCampaignsGridFields.CampaignType}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.Type, "%Campaign type")}
                    cell={(
                        (props: GridCellProps) => {
                            const campaign = props.dataItem as CampaignGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(campaign.campaignType, campaign.campaignType)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.CampaignType)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.CampaignType]} />
            ),
            (
                <GridColumn
                    key={"campaigns-ownedby"}
                    field={CRMCampaignsGridFields.OwnedByName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.OwnedBy, "%Owned by")}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.OwnedByName)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.OwnedByName]} />
            ),
            (
                <GridColumn
                    key={"campaigns-creationdate"}
                    field={CRMCampaignsGridFields.CreatedOn}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.CreationDate, "%Creation date")}
                    format={'{0:g}'}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.CreatedOn)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.CreatedOn]} />
            ),
            (
                <GridColumn
                    key={"campaigns-status"}
                    field={CRMCampaignsGridFields.Status}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.Status, "%Status")}
                    cell={(
                        (props: GridCellProps) => {
                            const campaign = props.dataItem as CampaignGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(campaign.status, campaign.status)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.Status)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.Status]} />
            ),
            (
                <GridColumn
                    key={"campaigns-executiondate"}
                    field={CRMCampaignsGridFields.ExecutionDate}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.ExecutionDate, "%Execution date")}
                    format={'{0:g}'}
                    width={this.calculateColumnWidth(CRMCampaignsGridFields.ExecutionDate)}
                    orderIndex={this.props.columnOrder[CRMCampaignsGridFields.ExecutionDate]} />
            ),
        ];

        return columns;
    }

    render() {
        let isGridBusy = this.props.isLoading ||
            this.props.createStatus === ServerOperationStatus.INPROGRESS ||
            this.props.cloneStatus === ServerOperationStatus.INPROGRESS;

        return (
            <Grid id={'CampaignsGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={this.props.campaigns.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: this.state.selectedId === idGetter(item),
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    selectedField={SELECTED_FIELD}
                    selectable={{ enabled: true, mode:'single' }}
                    onSelectionChange={this.onRowSelect.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}

                    onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                >
                    <GridToolbar>
                        <CampaignsToolbar
                            isGridBusy={isGridBusy}
                            selectedItem={this.state.selectedId}
                            filters={this.props.filters}
                            requestData={(filters) => {
                                this.props.requestCampaigns(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                            }}
                            onNewCampaignClick={this.onNewCampaignClick.bind(this)}
                            onCloneCampaignClick={this.onCloneCampaignClick.bind(this)}
                        />
                    </GridToolbar>

                    {this.columns}
                </AMWGrid>

                <NewCampaignModal
                    open={this.props.createStatus !== ServerOperationStatus.NONE &&
                        this.props.createStatus !== ServerOperationStatus.SUCCESS }
                    onSubmit={this.onNewCampaignSubmit.bind(this)}
                    onClose={this.onNewCampaignCancel.bind(this)}
                    createStatus={this.props.createStatus}
                />
            </Grid>
        );
    }
}
registerForLocalization(CampaignsTab);

export default connect(
    (state: ApplicationState) : CampaignsTabStateProps => {
        return {
            pageKey: UserPreferencePage.CRMCampaigns,
            ...state.crm?.campaigns,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : CampaignsTabDispatchProps => {
        let crmActions = bindActionCreators({...CRMStore.campaignsActionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...crmActions,
            ...usersActions
        };
    },
)(CampaignsTab as any);