import { nonSystemPartyTypes, PartyType } from "~enums/partyType";
import LocalizationKeys from "./localizationKeys";

const NewPartyKeys = {
    CRMCompanies: "NewParty-CRMCompanies-New",
    CustomsDocumentFrom: "CustomsDocument-From",
    CustomsDocumentTo: "CustomsDocument-To",
    GeneralInfoBilledTo: "NewParty-GeneralInfo-BilledTo",
    GeneralInfoBooker: "NewParty-GeneralInfo-Booker",
    GeneralInfoClient: "NewParty-GeneralInfo-Client",
    GeneralInfoConsignee: "NewParty-GeneralInfo-Consignee",
    GeneralInfoShipper: "NewParty-GeneralInfo-Shipper",
    ItemDetailsOwner: "NewParty-ItemDetails-Owner",
    ItemDetailsBuyer: "NewParty-ItemDetails-Buyer",
    ItemDetailsSeller: "NewParty-ItemDetails-Seller",
    ManagementCompanies: "NewParty-Management-Companies",
    NewJobClient: "NewParty-NewJob-Client",
    NewJobBooker: "NewParty-NewJob-Booker",
    NewOpportunityCompany: "NewParty-NewOpportunityDealer-Company",
    NewOrderDealer: "NewParty-NewOrder-Dealer",
    NewQuoteClient: "NewParty-NewQuote-Client",
    NewQuoteShipper: "NewParty-NewQuote-Shipper",
    NewSurveyClient: "NewParty-NewSurvey-Client",
    NewSurveyShipper: "NewParty-NewSurvey-Shipper",
    ShipmentShipper: "NewParty-Shipment-Shipper",
    ShipmentConsignee: "NewParty-Shipment-Consignee",
    ShipmentTrucker: "NewParty-Shipment-Trucker",
    ShipmentCarrier: "NewParty-Shipment-Carrier",
    ShipmentCustoms: "NewParty-Shipment-Customs",
    ShipmentForwardingAgent: "NewParty-Shipment-ForwAgent",
    ShipmentDestAgent: "NewParty-Shipment-DestAgent",
    PurchaseOrderDealer: "NewParty-PurchaseOrder-Dealer",
    QuickEntryClient: "NewParty-QuickEntry-Client",
    QuickEntryConsignee: "NewParty-QuickEntry-Consignee",
    QuickEntryShipper: "NewParty-QuickEntry-Shipper",
} as const;
export type NewPartyKeysType = typeof NewPartyKeys[keyof typeof NewPartyKeys];
export { NewPartyKeys };

const getPartyTypesForKey = (key: NewPartyKeysType): Array<string> => {
    switch (key) {
        case (NewPartyKeys.CRMCompanies):
            return [PartyType.Lead];

        case (NewPartyKeys.GeneralInfoBilledTo):
        case (NewPartyKeys.GeneralInfoClient):
        case (NewPartyKeys.ItemDetailsOwner):
        case (NewPartyKeys.ItemDetailsBuyer):
        case (NewPartyKeys.ItemDetailsSeller):
        case (NewPartyKeys.NewJobClient):
        case (NewPartyKeys.NewQuoteClient):
        case (NewPartyKeys.NewSurveyClient):
        case (NewPartyKeys.QuickEntryClient):
            return [PartyType.Client, PartyType.Dealer];

        case (NewPartyKeys.GeneralInfoConsignee):
        case (NewPartyKeys.GeneralInfoShipper):
        case (NewPartyKeys.NewJobBooker):
        case (NewPartyKeys.NewQuoteShipper):
        case (NewPartyKeys.NewSurveyShipper):
        case (NewPartyKeys.QuickEntryConsignee):
        case (NewPartyKeys.QuickEntryShipper):
        case (NewPartyKeys.ShipmentConsignee):
            return [PartyType.Client, PartyType.Dealer, PartyType.Vendor];

        case (NewPartyKeys.GeneralInfoBooker):
            return [PartyType.Client, PartyType.Dealer, PartyType.Vendor, PartyType.Division];

        case (NewPartyKeys.NewOrderDealer):
        case (NewPartyKeys.PurchaseOrderDealer):
            return [PartyType.Dealer];

        case (NewPartyKeys.NewOpportunityCompany):
            return nonSystemPartyTypes;

        case (NewPartyKeys.ManagementCompanies):
            return Object.values(PartyType);

        case (NewPartyKeys.ShipmentShipper):
            return [PartyType.Client, PartyType.Dealer];

        case (NewPartyKeys.ShipmentCarrier):
        case (NewPartyKeys.ShipmentTrucker):
            return [PartyType.Client, PartyType.Vendor];

        case (NewPartyKeys.ShipmentForwardingAgent):
            return [PartyType.Client, PartyType.Vendor];

        case (NewPartyKeys.ShipmentDestAgent):
        case (NewPartyKeys.ShipmentCustoms):
            return [PartyType.Vendor];
        default:
            return [];
    }
};
export { getPartyTypesForKey };

const getNewPartyTitleByKey = (key: NewPartyKeysType): string => {
    switch (key) {
        case (NewPartyKeys.GeneralInfoBilledTo):
            return LocalizationKeys.NewParty.Titles.BilledTo;
        case (NewPartyKeys.GeneralInfoClient):
        case (NewPartyKeys.NewJobClient):
        case (NewPartyKeys.QuickEntryClient):
        case (NewPartyKeys.NewSurveyClient):
        case (NewPartyKeys.NewQuoteClient):
            return LocalizationKeys.NewParty.Titles.Client;
        case (NewPartyKeys.GeneralInfoShipper):
        case (NewPartyKeys.QuickEntryShipper):
        case (NewPartyKeys.ShipmentShipper):
        case (NewPartyKeys.NewSurveyShipper):
        case (NewPartyKeys.NewQuoteShipper):
            return LocalizationKeys.NewParty.Titles.Shipper;
        case (NewPartyKeys.GeneralInfoConsignee):
        case (NewPartyKeys.QuickEntryConsignee):
        case (NewPartyKeys.ShipmentConsignee):
            return LocalizationKeys.NewParty.Titles.Consignee;
        case (NewPartyKeys.GeneralInfoBooker):
        case (NewPartyKeys.NewJobBooker):
            return LocalizationKeys.NewParty.Titles.Booker;
        case (NewPartyKeys.NewOrderDealer):
        case (NewPartyKeys.PurchaseOrderDealer):
            return LocalizationKeys.NewParty.Titles.Dealer;
        case (NewPartyKeys.CRMCompanies):
        case (NewPartyKeys.NewOpportunityCompany):
            return LocalizationKeys.NewParty.Titles.Company;
        case (NewPartyKeys.ShipmentTrucker):
            return LocalizationKeys.NewParty.Titles.Trucker;
        case (NewPartyKeys.ShipmentCarrier):
            return LocalizationKeys.NewParty.Titles.Carrier;
        case (NewPartyKeys.ShipmentCustoms):
            return LocalizationKeys.NewParty.Titles.Vendor;
        case (NewPartyKeys.ShipmentForwardingAgent):
        case (NewPartyKeys.ShipmentDestAgent):
            return LocalizationKeys.NewParty.Titles.Agent;
        case (NewPartyKeys.ItemDetailsOwner):
            return LocalizationKeys.NewParty.Titles.Owner;
        case (NewPartyKeys.ItemDetailsBuyer):
            return LocalizationKeys.NewParty.Titles.Buyer;
        case (NewPartyKeys.ItemDetailsSeller):
            return LocalizationKeys.NewParty.Titles.Seller;
        default:
            return "";
    }
};
export { getNewPartyTitleByKey };