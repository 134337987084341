import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import history from '~services/history/history';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as QuotationsStore from '~store/pages/mainViews/quotations';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import ProjectsGridFields from '~enums/gridFields/projects';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { checkboxColumnWidth } from '~enums/constants';
import ProjectGridItem from '~models/project/projectGridItem';

import { Grid } from '@mui/material';
import { getter } from "@progress/kendo-react-common";
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable, SELECTED_FIELD } from '~components/datagrid/types';
import ConfirmationDialog from '~components/infra/ConfirmationDialog';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import ProjectsToolbar from './ProjectsToolbar';
import LocalizationKeys from '~enums/localizationKeys';


const DATA_ITEM_KEY: string = "projectId";
const idGetter = getter(DATA_ITEM_KEY);

type ProjectsTabStateProps = ScreenStore.ScreenState
    & QuotationsStore.ProjectsTabState
    & GridFilterable
    & GridPageable
    & GridSortable
    & GridContainerStateProps;

type ProjectsTabDispatchProps = typeof QuotationsStore.actionCreators
    & GridContainerDispatchProps;

type ProjectsTabProps = ProjectsTabStateProps
    & ProjectsTabDispatchProps;

type ProjectsTabState = {
    containerWidth: number;
    selectedId?: number;
}

const minColumnWidth = {
    [SELECTED_FIELD]: 50,
    [ProjectsGridFields.UniqueId]: 100,
    [ProjectsGridFields.Status]: 110,
    [ProjectsGridFields.Description]: 150,
    [ProjectsGridFields.StartDate]: 125,
    [ProjectsGridFields.CompletedDate]: 125,
    [ProjectsGridFields.ClientRef1]: 125,
    [ProjectsGridFields.ClientRef2]: 125,
};

class ProjectsTab extends GridContainer<ProjectsTabProps, ProjectsTabState> {
    constructor(props: Readonly<ProjectsTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestProjects(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#ProjectsGridContainer .k-grid-header');
        let tabs = document.querySelector('#QuotationsTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('ProjectsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: ProjectsTabProps) {
        if (!equal(prevProps.projects, this.props.projects)) {
            this.setState({ selectedId: undefined, });
        }

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#ProjectsGridContainer .k-grid-header');
            let tabs = document.querySelector('#QuotationsTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }

        if (prevProps.projectDeleteStatus !== this.props.projectDeleteStatus && this.props.projectDeleteStatus === ServerOperationStatus.SUCCESS) {
            this.props.requestProjects(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('ProjectsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onNewProjectClick() {
        this.props.createProject();
    }

    private onDeleteProjectClick = () => {
        this.props.startDeleteProject();
    }

    private onDeleteProjectClose = () => {
        this.props.cancelDeleteProject();
    }

    private onDeleteProjectSubmit = () => {
        !!this.state.selectedId && this.props.deleteProject(this.state.selectedId);
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestProjects(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestProjects(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let project = event.dataItem as ProjectGridItem;
        history.push(`/projects/${project.projectId}`);
    }

    private onRowSelect(event: GridSelectionChangeEvent) {
        if (event.nativeEvent.target.tagName === 'A') {
            return false;
        }

        this.setState({
            selectedId: GridUtils.getSelectedIds(event, !!this.state.selectedId ? [this.state.selectedId] : [], DATA_ITEM_KEY)[0],
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"projects-check"}
                    field={SELECTED_FIELD}
                    width={checkboxColumnWidth}
                    headerClassName={'grid__header--empty'}
                    className={'grid__cell--checkbox'}
                    reorderable={false}
                    orderIndex={0}
                    resizable={false}
                />
            ),
            (
                <GridColumn
                    key={"projects-number"}
                    field={ProjectsGridFields.UniqueId}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.UniqueId, "%Project #")}
                    cell={(
                        (props: GridCellProps) => {
                            const project = props.dataItem as ProjectGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/projects/${project.projectId}`}>{project.uniqueId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(ProjectsGridFields.UniqueId) ?? 100}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.UniqueId]} />
            ),
            (
                <GridColumn
                    key={"projects-status"}
                    field={ProjectsGridFields.Status}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.Status, "%Status")}
                    cell={(
                        (props: GridCellProps) => {
                            const project = props.dataItem as ProjectGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(project.status, project.status)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(ProjectsGridFields.Status)}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.Status]} />
            ),
            (
                <GridColumn
                    key={"projects-description"}
                    field={ProjectsGridFields.Description}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.Description, "%Description")}
                    width={this.calculateColumnWidth(ProjectsGridFields.Description)}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.Description]} />
            ),
            (
                <GridColumn
                    key={"projects-startDate"}
                    field={ProjectsGridFields.StartDate}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.StartDate, "%Started")}
                    format="{0:d}"
                    width={this.calculateColumnWidth(ProjectsGridFields.StartDate) ?? 125}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.StartDate]} />
            ),
            (
                <GridColumn
                    key={"projects-completedDateDate"}
                    field={ProjectsGridFields.CompletedDate}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.CompletionDate, "%Completed")}
                    format="{0:d}"
                    width={this.calculateColumnWidth(ProjectsGridFields.CompletedDate) ?? 125}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.CompletedDate]} />
            ),
            (
                <GridColumn
                    key={"projects-clientRef1"}
                    field={ProjectsGridFields.ClientRef1}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.ClientRef1, "%Client ref 1")}
                    width={this.calculateColumnWidth(ProjectsGridFields.ClientRef1)}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.ClientRef1]} />
            ),
            (
                <GridColumn
                    key={"projects-clientRef2"}
                    field={ProjectsGridFields.ClientRef2}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.ClientRef2, "%Client ref 2")}
                    width={this.calculateColumnWidth(ProjectsGridFields.ClientRef2)}
                    orderIndex={this.props.columnOrder[ProjectsGridFields.ClientRef2]} />
            ),
        ];

        return columns;
    }

    render() {
        const localization = provideLocalizationService(this);

        let isGridBusy = this.props.isLoading ||
            this.props.projectCreateStatus === ServerOperationStatus.INPROGRESS ||
            this.props.projectDeleteStatus === ServerOperationStatus.INPROGRESS;

        return (
            <Grid container>
                <Grid item xs={12} id={'ProjectsGridContainer'}>
                    <AMWGrid
                        sticky={true}
                        scrollable={'scrollable'}

                        data={this.props.projects.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: this.state.selectedId === idGetter(item),
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        total={this.props.count}

                        pageable={{ buttonCount: 4, pageSizes: true }}
                        skip={this.props.skip}
                        pageSize={this.props.pageSize}
                        onPageChange={this.onPageChange.bind(this)}

                        sortable={true}
                        sort={this.props.sorting}
                        onSortChange={this.onSortChange.bind(this)}

                        selectedField={SELECTED_FIELD}
                        selectable={{enabled: true, mode:'single'}}
                        onSelectionChange={this.onRowSelect.bind(this)}

                        reorderable={true}
                        onColumnReorder={this.onColumnReorder.bind(this)}

                        resizable={true}
                        onColumnResize={this.onColumnResize.bind(this)}

                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                    >
                        <GridToolbar>
                            <ProjectsToolbar
                                selectedItem={this.state.selectedId}
                                isGridBusy={isGridBusy}
                                filters={this.props.filters}
                                requestData={(filters) => {
                                    this.props.requestProjects(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                                }}
                                onNewProjectClick={this.onNewProjectClick.bind(this)}
                                onDeleteProjectClick={this.onDeleteProjectClick.bind(this)}
                            />
                        </GridToolbar>

                        {this.columns}
                    </AMWGrid>

                    <ConfirmationDialog
                        open={this.props.projectDeleteStatus !== ServerOperationStatus.NONE &&
                            this.props.projectDeleteStatus !== ServerOperationStatus.SUCCESS}
                        title={localization.toLanguageString(LocalizationKeys.Projects.Delete.Title, "%Delete project")}
                        text={localization.toLanguageString(LocalizationKeys.Projects.Delete.Text, "%Selected project will be permanently deleted. Proceed?")}
                        onSubmit={this.onDeleteProjectSubmit.bind(this)}
                        onCancel={this.onDeleteProjectClose.bind(this)}
                    />
                </Grid>
            </Grid>
        );
    }
}
registerForLocalization(ProjectsTab as any);

export default connect(
    (state: ApplicationState) : ProjectsTabStateProps => {
        return {
            pageKey: UserPreferencePage.Projects,
            ...state.quotations?.projects,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.Projects}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.Projects}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.Projects}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.Projects}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.Projects}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : ProjectsTabDispatchProps => {
        let quoteActions = bindActionCreators({...QuotationsStore.actionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...quoteActions,
            ...usersActions,
        }
    },
)(ProjectsTab as any);