import React from 'react';
import { DatePicker, DatePickerProps } from '@progress/kendo-react-dateinputs';
import { LinearProgress } from '@mui/material';

import '~css/components/controls/DatePickerWrapper.scss';


type DatePickerWrapperProps = DatePickerProps &
{
    inProgress?: boolean;
};

export default class DatePickerWrapper extends React.Component<DatePickerWrapperProps> {
    __drop?: DatePicker | null;

    render() {
        return (
            <div className={'DatePickerWrapper'}>
                <DatePicker
                    ref={(drop) => {this.__drop = drop;}}
                    {...this.props}
                    placeholder={this.props.placeholder ?? ''}
                />

                {!!this.props.inProgress && (
                    <LinearProgress className={`DatePickerWrapper__Progress`} />
                )}
            </div>
        );
    }
}