import { AppThunkAction } from '../ApplicationState';
import { Action, Reducer } from 'redux';
import BreadcrumbItem from '~models/breadcrumb/breadcrumbItem';
import history from '~services/history/history';

export interface BreadcrumbsState {
    crumbs: BreadcrumbItem[];
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface UpdateAction {
    type: 'BREADCRUMBS_UPDATE';
    crumbs: BreadcrumbItem[];
}

type KnownAction = UpdateAction;

export const actionCreators = {
    setCrumbs: (crumbs: BreadcrumbItem[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breadcrumbs) {
            dispatch({ type: 'BREADCRUMBS_UPDATE', crumbs: crumbs });
        }
    },
    addCrumb: (newCrumb: BreadcrumbItem): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breadcrumbs) {
            let newCrumbs = [...appState.breadcrumbs.crumbs, newCrumb];

            dispatch({ type: 'BREADCRUMBS_UPDATE', crumbs: newCrumbs });
        }
    },
    updateCrumb: (newCrumb: BreadcrumbItem): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breadcrumbs) {
            let newCrumbs = [...appState.breadcrumbs.crumbs];
            let toUpdate = newCrumbs.find(x => x.id === newCrumb.id);
            let index = newCrumbs.findIndex(x => x.id === newCrumb.id);
            newCrumbs[index] = {...toUpdate, ...newCrumb};

            dispatch({ type: 'BREADCRUMBS_UPDATE', crumbs: newCrumbs });
        }
    },
    goToCrumb: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.breadcrumbs) {
            let newCrumbs: BreadcrumbItem[] = [];

            for (let i = 0; i < appState.breadcrumbs.crumbs.length; i++) {
                let item = appState.breadcrumbs.crumbs[i];
                newCrumbs.push(item);
                if (item.id === id) {
                    !!item.url && history.push(item.url);
                    break;
                }
            }

            dispatch({ type: 'BREADCRUMBS_UPDATE', crumbs: newCrumbs });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: BreadcrumbsState = {
    crumbs: [],
 };

export const reducer: Reducer<BreadcrumbsState> = (state: BreadcrumbsState | undefined, incomingAction: Action): BreadcrumbsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'BREADCRUMBS_UPDATE':
            return {
                crumbs: action.crumbs
            };
    }

    return state;
};